import React, { useEffect } from "react";
import "./steps.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import QuizComponent from "../../../components/estruturaQuiz";

export default function Step07(props) { 
  const GlobalContext = useGlobalContext();
  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#05BF00');
    document.documentElement.style.setProperty('--bgCard', '#05BF00');
    document.documentElement.style.setProperty('--textButton', '#05BF00');

    document.documentElement.style.setProperty('--border', '#FFF');
    document.documentElement.style.setProperty('--bgbutton', '#FFF');
    document.documentElement.style.setProperty('--text', '#FFF');

    document.documentElement.style.setProperty('--element01', '#FFB7AB');
    document.documentElement.style.setProperty('--element02', '#465EFF');

    document.documentElement.style.setProperty('--bgPerguntaBox', GlobalContext.steps[props.currentStep - 2].colorBgPerguntaBox);
  },[]);

  return <QuizComponent {...props} nextStep={8} />;
}
