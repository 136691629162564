import React, { useEffect, useState } from "react";
import LogoSvg from "../../components/logoSvg";
import Slider from "react-slick";
import { useParams } from 'react-router-dom';


import CuidaPinkMoney from "./cuidar-pink-money.json";
import JuntarCasamento from "./juntar-casamento.json";
import JuntarDimdim from "./juntar-dimdim.json";
import JuntarDinheiroViagem from "./juntar-dinheiro-viagem.json";
import JuntarPinkMoney from "./juntar-pink-money.json";
import JuntarTransicao from "./juntar-transicao.json";
import LivrarDividas from "./livar-dividas.json";
import LivarImpulsividade from "./livrar-impulsividade.json";
import SemPerrengue from "./sem-perrengue.json";

import "./index.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PageFinishi() {
  const [conteudo, setConteudo] = useState(SemPerrengue);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    switch (id) {
      case 'CuidaPinkMoney':
        setConteudo(CuidaPinkMoney);
        break;
      case 'JuntarCasamento':
        setConteudo(JuntarCasamento);
        break;
      case 'JuntarDimdim':
        setConteudo(JuntarDimdim);
        break;
      case 'JuntarDinheiroViagem':
        setConteudo(JuntarDinheiroViagem);
        break;
      case 'JuntarPinkMoney':
        setConteudo(JuntarPinkMoney);
        break;
      case 'JuntarTransicao':
        setConteudo(JuntarTransicao);
        break;
      case 'LivrarDividas':
        setConteudo(LivrarDividas);
        break;
      case 'LivarImpulsividade':
        setConteudo(LivarImpulsividade);
        break;
      case 'SemPerrengue':
        setConteudo(SemPerrengue);
        break;
      default:
        setConteudo(SemPerrengue);
        break;
    }
  }, [id])

  // const carouselItems = [
  //   {
  //     id: 1,
  //     image: require("../../assets/img/video.png"),
  //     text: "Reunião do Copom de março de 2023 - Podcast InvesTalk | BB",
  //   },
  //   {
  //     id: 2,
  //     image: require("../../assets/img/video.png"),
  //     text: "Reunião do Copom de março de 2023 - Podcast InvesTalk | BB",
  //   },
  //   {
  //     id: 3,
  //     image: require("../../assets/img/video.png"),
  //     text: "Reunião do Copom de março de 2023 - Podcast InvesTalk | BB",
  //   },
  // ];

  const carouselItemsPalestras = [
    {
      id: 1,
      data: '11h',
      text: "Aprendendo a Prosperar: Transformando Sonhos em Realidade",
    },
    {
      id: 2,
      data: '12h',
      text: "Como fazer as pazes com o dinheiro?",
    },
    {
      id: 3,
      data: '13h',
      text: "Como ter mais benefícios com gastos do dia a dia",
    },
    {
      id: 4,
      data: '14h',
      text: "E quando as contas não fecham?",
    },
    {
      id: 5,
      data: '15h',
      text: "Cuidar do dinheiro também é autocuidado, mas qual é o skincare?",
    },
    {
      id: 6,
      data: '16h',
      text: "3 formas para começar a guardar dinheiro",
    },
    {
      id: 7,
      data: '17h',
      text: "Como fazer as pazes com o dinheiro?",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    asNavFor: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const renderCarouselItems = () => {
    return conteudo.map((item, index) => (
      <div key={index + 1} className="carousel-item">
        <a href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/img/conteudos/${item.image}`)} alt={item.text} />
        </a>
        <p>{item.text}</p>
      </div>
    ));
  };

  const renderCarouselPalestras = () => {
    return carouselItemsPalestras.map((item) => (
      <div key={item.id} className="carousel-item carousel-item-palestras">
        <span>{item.data}</span>
        <p>{item.text}</p>
      </div>
    ));
  };

  function goTo() {
    history.push("/parabens")
  }

  function title() {
    switch (id) {
      case 'CuidaPinkMoney':
        return 'Quero cuidar melhor do meu pink money';
      case 'JuntarCasamento':
        return 'Quero guardar dinheiro para o casamento dos sonhos';
      case 'JuntarDimdim':
        return 'Quero conseguir juntar dindin para realizar meus sonhos ainda este ano';
      case 'JuntarDinheiroViagem':
        return 'Quero juntar dinheiro para uma minha viagem incrível';
      case 'JuntarPinkMoney':
        return 'Quero conseguir juntar meu pink money para realizar um sonho';
      case 'JuntarTransicao':
        return 'Quero juntar dinheiro pra minha transição';
      case 'LivrarDividas':
        return 'Quero sair da pior e me livrar das dívidas';
      case 'LivarImpulsividade':
        return 'Quero me livrar da impulsividade nas compras';
      case 'SemPerrengue':
        return 'Penso em viver sem perrengue break';
      default:
        return '';
    }
  }

  return (
    <div className="page-finish">
      <header>
        <div className="content-finish">
          <h2>Investir em conhecimento também é investir em você.</h2>
          <p>Que tal dar os próximos passos com o nosso conteúdo sobre educação financeira? Confira o mais indicado pra você.</p>
        </div>
      </header>

      <section className="invesTalk">
        <h2>{title()}</h2>
        <Slider {...settings}>{renderCarouselItems()}</Slider>
      </section>

      <section className="palestras">
        <h2>Minipalestras</h2>
        <br /><br />
        <Slider {...settings}>{renderCarouselPalestras()}</Slider>
      </section>

      <section className="agende">
        <h2>Bate-papo <br /> financeiro</h2>
        <p>Você quer saber mais sobre seu comportamento financeiro e como dar os primeiro passos para conquistar a liberdade financeira para ser quem você é? <b>Agende um horário com nossos especialistas.</b></p>
        <a className="button" href="https://calendly.com/invistaemvc/consultoria-invista-em-vc" target="_blank">clique aqui</a>
      </section>

      {/* <section className="agende">
        <h2>Agende consultoria</h2>
        <button>clique aqui</button>
      </section> */}

      <section className="card">
        <h2>Faça seu cartão Orgulho</h2>
        <img src={require("../../assets/img/card.png")} alt="Card" />
        {/* <button>clique aqui</button> */}
        <p>Entre no app Ourocard e solicite o seu.</p>
      </section>

      <section className="brind">
        <h2>No final do arco-íris, <br />tem um brinde esperando por você</h2>
        <p>Agradecemos a sua participação no quiz!
          Agora retire seu brinde no estande BB na 22ª
          Feira Cultural da Diversidade LGBT+
        </p>
        {/* <Slider {...settings}>{renderCarouselItems()}</Slider> */}
      </section>

      <section className="brind playlist">
        <h2>Playlist <br /> Orgulho BB</h2>
        <h3>Todes no clima da Parada</h3>
        <img src={require("../../assets/img/spotify.png")} alt="Playlist Spotify" />
        <p>Uma música para cada letra da comunicade LGBTQIAPN+</p>
        <a href="https://open.spotify.com/playlist/0SgUVRhSetcO06ITyWZHgc?si=88fc0eb49da94f91" target="_blank">
          <img src={require("../../assets/img/spotifyicon.png")} alt="Playlist Spotify" />
          Clique para ouvir
        </a>
      </section>

      <section className="finish">
        <button onClick={goTo}>Finalizar</button>
      </section>

      <div className="bottom">
        <LogoSvg />
      </div>
    </div>
  );
}
