import React, { useEffect } from "react";
import "./steps.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import QuizComponent from "../../../components/estruturaQuiz";

export default function Step04(props) { 
  const GlobalContext = useGlobalContext();
  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#FF3131');
    document.documentElement.style.setProperty('--bgCard', '#FF3131');
    document.documentElement.style.setProperty('--textButton', '#FF3131');

    document.documentElement.style.setProperty('--border', '#FFF');
    document.documentElement.style.setProperty('--bgbutton', '#FFF');
    document.documentElement.style.setProperty('--text', '#FFF');

    document.documentElement.style.setProperty('--element01', '#6D2E18');
    document.documentElement.style.setProperty('--element02', '#000000');

    document.documentElement.style.setProperty('--bgPerguntaBox', GlobalContext.steps[props.currentStep - 2].colorBgPerguntaBox);
  },[]);

  return <QuizComponent {...props} nextStep={5} />;
}
