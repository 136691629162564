import React, { useEffect, useState } from "react";
import "./steps.scss";
import MaskLoading from "../../../components/maskLoading/maskLoading";
import { Firestore } from "../../../utils/firebaseUtils";
import { useGlobalContext } from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import MapeamentoCards from "../../../utils/mapeamentoCards.json";
import UserService from "../../../services/user";

const _ = require("lodash");


export default function Step09(props) {
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const isChromeOnIphone = /CriOS/.test(navigator.userAgent) && /iPhone/.test(navigator.userAgent);
  const history = useHistory();

  const GlobalContext = useGlobalContext();

  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#FCFC30');
    document.documentElement.style.setProperty('--bgCard', '#FCFC30');
    document.documentElement.style.setProperty('--textButton', '#FCFC30');

    document.documentElement.style.setProperty('--border', '#F97A70');
    document.documentElement.style.setProperty('--bgbutton', '#F97A70');
    document.documentElement.style.setProperty('--text', '#F97A70');

    document.documentElement.style.setProperty('--element01', '#735CC6');
    document.documentElement.style.setProperty('--element02', '#FCFC30');
  }, []);

  function idRedireciona() {
    const arrayPerguntas = GlobalContext.steps[GlobalContext.steps.length - 1];
    const filteredItems = arrayPerguntas.perguntas.findIndex((item) => item.active);

    switch (filteredItems) {
      case 0:
        return 'SemPerrengue'
      case 1:
        return 'JuntarPinkMoney'
      case 2:
        return 'JuntarDinheiroViagem'
      case 3:
        return 'JuntarTransicao'
      case 4:
        return 'JuntarCasamento'
      case 5:
        return 'LivarImpulsividade'
      case 6:
        return 'JuntarDimdim'
      case 7:
        return 'CuidaPinkMoney'
      case 8:
        return 'LivrarDividas'
      default:
        return ''
    }
  }

  function nextStep() {
    setLoading(true);

    Firestore.collection("cards")
      .get()
      .then((response) => {
        if (response.size) {
          let registro = [];
          response.docs.forEach((element) => {
            const save = { ...element.data() }
            registro.push(save);
          });
          salvarDados(registro);
        } else {
          salvarDados([]);
        }
      }).catch(() => {
        setLoading(false);
        toast.error("Não foi possível buscar cards disponíveis");
      });
  }


  function salvarDados(registrosCards) {
    const cardsValidos = MapeamentoCards.filter(e => e.valido);
    const cardsFiltrados = cardsValidos.filter(item =>
      !registrosCards.some(jsonItem => _.isEqual(jsonItem, item))
    );

    if (!cardsFiltrados.length) {
      setLoading(false);
      toast.error("Ops, os cards acabaram :(")
    } else {

      const randomIndex = Math.floor(Math.random() * cardsFiltrados.length);
      const cardRandomico = cardsFiltrados[randomIndex];
      try {
        Firestore.collection('users')
          .add({
            ...GlobalContext.dadosUser,
            quiz: GlobalContext.steps,
            date: new Date(),
            card: cardRandomico
          })
          .then((response => {
            setNext(true);
            UserService.SendMail({email: GlobalContext.dadosUser.email, type: idRedireciona()});
            localStorage.setItem('finalizado', idRedireciona());
            Firestore.collection('cards').add(cardRandomico);
            setTimeout(() => {
              history.push(`/finish/${idRedireciona()}`);
            }, 1000);
          })).catch(() => {
            setLoading(false);
            toast.error("Erro ao salvar Card.")
          })
      } catch (error) {
        setLoading(false);
        toast.error("Erro ao salvar Card.")
      }
    }
  }

  function validNext() {
    if (
      GlobalContext.dadosUser.descricao.length < 10 ||
      GlobalContext.dadosUser.descricao.length > 220
    ) {
      return false;
    }
    return true;
  }

  return <>
    <div className="step 01">
      <div className="content-cardStep">
        <div className={`card-form ${next && 'close'}`}>
          <div className="form">
            <h2>Em que você gostaria de <br /> investir seu pink money?</h2>
            <textarea
              rows="10"
              autoComplete={isChromeOnIphone ? "no-chrome-autocomplete" : "off"}
              placeholder="Conta pra gente em 220 caracteres."
              value={GlobalContext.dadosUser.descricao}
              onChange={(value) => GlobalContext.setDadosUser({ ...GlobalContext.dadosUser, descricao: value.target.value })}
            ></textarea>
            <span className="contador">
              {GlobalContext.dadosUser.descricao.length}/220
            </span>
            <div className="action">
              <button onClick={nextStep} disabled={!validNext()}>Enviar</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`animation-step ${next && 'close'}`}>
        <div className="element01"></div>
        <div className="element02"></div>
        <div className="revela"></div>
      </div>
    </div>
    {loading &&
      <div className="global-loading">
        <MaskLoading />
      </div>
    }
  </>;
}
