import React, { useState, useContext, createContext } from 'react';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const context = useGlobalProvider();
  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

function useGlobalProvider() {
  const [user, setUser] = useState();
  const [steps, setStep] = useState([
    {
      color: "#465EFF",
      enunciado: 'Saiu o salário, você',
      perguntasBox: true,
      colorBgPerguntaBox: '#FCFC30',
      perguntas: [
        {
          pergunta: 'Faz a loka e sai gastando horrores',
          active: false,
        },
        {
          pergunta: 'Guarda o pink money, pensando no futuro rainbow',
          active: false,
        },
      ]
    },
    {
      color: "#FCFC30",
      enunciado: 'Você se sente confiante, cuidando do seu valiosíssimo dindim?',
      perguntas: [
        {
          pergunta: "NENHUM POUCO",
          active: false
        },
        {
          pergunta: "UM POUCO",
          active: false
        },
        {
          pergunta: "MAIS OU MENOS. PELO MENOS NÃO FICO NO VERMELHO, BI!",
          active: false
        },
        {
          pergunta: "CONFIANTE, MAS CONHEÇO QUEM FAZ MELHOR",
          active: false
        },
        {
          pergunta: "MUITO CONFIANTE, SOU FODA",
          active: false
        },
      ]
    },
    {
      color: "#FF3131",
      enunciado: 'Como é sua relação com dinheiro? Sai mais do que entra?',
      perguntas: [
        {
          pergunta: "Tóxica! Os gastos são MUITO MAIORES que a renda",
          active: false,
        },
        {
          pergunta: "Saio um pouco da linha! Os gastos são UM POUCO maiores que a renda",
          active: false,
        },
        {
          pergunta: "Nem lá, nem cá! Os gastos foram MAIS OU MENOS iguais a renda",
          active: false,
        },
        {
          pergunta: "Controlada! Os gastos foram UM POUCO MENORES que a renda",
          active: false,
        },
        {
          pergunta: "Tô no lucro! Os gastos foram MUITO MENORES que a renda",
          active: false,
        },
      ]
    },
    {
      color: "#6D2E18",
      enunciado: 'A preocupação com dindim estraga sua beleza?',
      perguntas: [
        { 
          pergunta: "Não me identifico",
          active: false,
        },
        { 
          pergunta: "Me identifico pouco",
          active: false,
        },
        { 
          pergunta: "Me identifico mais ou menos",
          active: false,
        },
        { 
          pergunta: "Me identifico bem",
          active: false,
        },
        { 
          pergunta: "Totalmente eu",
          active: false,
        },
      ]
    },
    {
      color: "#FFB7AB",
      enunciado: 'Você guarda dinheiro, ou só fica na pior?',
      perguntas: [
        {
          pergunta: "Não sei nem o que é guardar dinheiro",
          active: false
        },
        {
          pergunta: "Guardo só quando sobra",
          active: false
        },
        {
          pergunta: "Guardo de vez em quando ",
          active: false
        },
        {
          pergunta: "Guardo quando pinta uma grana extra",
          active: false
        },
        {
          pergunta: "Meu bem, se isto é estar na pior, eu guardo é com regularidade",
          active: false
        },
      ]
    },
    {
      color: "#05BF00",
      enunciado: 'Na sua situação financeira atual, você daria conta de uma despesa babado, totalmente inesperada?',
      perguntas: [
        {
          pergunta: "Não, já tenho muitas dívidas",
          active: false
        },
        {
          pergunta: "Não tenho reserva, mas faria um empréstimo 	 ",
          active: false
        },
        {
          pergunta: "Tenho uma reservinha  ",
          active: false
        },
        {
          pergunta: "Tenho uma reserva, vai! (equivalente a 6 meses dos meus gastos) ",
          active: false
        },
        {
          pergunta: "Tenho uma reserva diva (maior do que 6 meses dos meus gastos)",
          active: false
        },
      ]
    },
    {
      color: "#735CC6",
      enunciado: 'Conte para a gente como gostaria de investir em você:',
      perguntas: [
        {
          pergunta: "Penso viver sem perrengue",
          active: false
        },
        {
          pergunta: "Quero conseguir juntar meu pink money para realizar um sonho ",
          active: false
        },
        {
          pergunta: "Quero juntar dinheiro para uma minha viagem incrível",
          active: false
        },
        {
          pergunta: "Quero juntar dinheiro pra minha transição ",
          active: false
        },
        {
          pergunta: "Quero guardar dinheiro para o casamento dos sonhos",
          active: false
        },
        {
          pergunta: "Quero me livrar da impulsividade nas compras",
          active: false
        },
        {
          pergunta: "Quero conseguir juntar dindim para realizar meus sonhos ainda este ano",
          active: false
        },
        {
          pergunta: "Quero cuidar melhor do meu pink money",
          active: false
        },
        {
          pergunta: "Quero sair da pior e me livrar das dívidas",
          active: false
        },
      ]
    },
  ]);
  const [dadosUser, setDadosUser] = useState({
    nome: '',
    cpf: null,
    telefone: null,
    email: '',
    termoAceite: false,
    descricao: '',
    aceiteMarketing: true,
    direitoDeImagens: true,
  });

  return {
    user,
    setUser,
    steps,
    setStep,
    dadosUser,
    setDadosUser,
  };
}
