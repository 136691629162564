import React, { useEffect } from "react";
import "./steps.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import QuizComponent from "../../../components/estruturaQuiz";

export default function Step02(props) { 
  const GlobalContext = useGlobalContext();
  useEffect(() => {
    document.documentElement.style.setProperty('--text', '#fff');
    document.documentElement.style.setProperty('--bg', '#465EFF');
    document.documentElement.style.setProperty('--element01', '#FCFC30');
    document.documentElement.style.setProperty('--element02', '#05BF00');
    document.documentElement.style.setProperty('--border', '#fff');
    document.documentElement.style.setProperty('--bgbutton', '#fff');
    document.documentElement.style.setProperty('--textButton', '#465EFF');
    document.documentElement.style.setProperty('--bgCard', '#465EFF');
    document.documentElement.style.setProperty('--bgPerguntaBox', GlobalContext.steps[props.currentStep - 2].colorBgPerguntaBox);
  },[]);

  return <QuizComponent {...props} nextStep={3} />;
}
