import React from "react";
import "./index.scss";
import Cores from "../../utils/cores.json";
import LogoSvg from "../../components/logoSvg";
import { useHistory } from "react-router-dom";


export default function HomePage() {
  const history = useHistory();

  function animation() {
    const items = Cores.map((e, index) => (
      <div
        className={`color color-${index}`}
        style={{ animationDelay: `${index * 0.1}s` }}
        key={index}
      ></div>
    ));

    const firstHalfLength = Math.ceil(items.length * 0.7);
    const firstHalf = items.slice(0, firstHalfLength);
    const secondHalf = items.slice(firstHalfLength);

    return (
      <>
        <div className="dividir" style={{ animationDelay: `1.9s` }}>
          {firstHalf}
        </div>
        <div className="content-home" style={{ animationDelay: `2.6s` }}>
          <div className="header">
            <div>
              <h4>Quem é você na</h4>
              <h2>fila da <br /> balada?</h2>
              <h3>Invista em vc</h3>
              <div className="button-wrapper">
                <button onClick={goToQuiz}  >Descubra como</button>
              </div>
            </div>
          </div>
          <div className="person">
            <img src={require("../../assets/img/person-home.png")} alt="Person" />
          </div>
        </div>
        <div className="dividir" style={{ animationDelay: `1.9s` }}>
          {secondHalf}
        </div>
      </>
    );
  }

  function goToQuiz() {
    history.push('/quiz');
  }

  return <>
    <div className="content">
      <div className="animation">
        {animation()}
      </div>
      <div className="bottom" style={{ animationDelay: `3.5s` }}>
        <div className="button-wrapper">
          <button onClick={goToQuiz}  >Descubra como</button>
        </div>
        <figure>
          <LogoSvg />
        </figure>
      </div>
    </div>
  </>;
}
