import React, { useEffect, useState } from "react";
import "./index.scss";
import LogoSvg from "../../components/logoSvg";
import { useGlobalContext } from "../../context/GlobalContext";

// Importe todos os componentes Step aqui
import Step01 from "./steps/01";
import Step02 from "./steps/02";
import Step03 from "./steps/03";
import Step04 from "./steps/04";
import Step05 from "./steps/05";
import Step06 from "./steps/06";
import Step07 from "./steps/07";
import Step08 from "./steps/08";
import Step09 from "./steps/09";

const steps = [
  Step01,
  Step02,
  Step03,
  Step04,
  Step05,
  Step06,
  Step07,
  Step08,
  Step09
];

export default function QuizPage() {
  const [currentStep, setCurrentStep] = useState(1);
  const GlobalContext = useGlobalContext();

  useEffect(() => {

    function distribuiCor(coluna) {
      switch (coluna) {
        case "0":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }
        case "1":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }

        case "2":
          return { text: "#bdb6ff", cor: "#623f99", logo: "amarelo" }
        case "3":
          return { text: "#bdb6ff", cor: "#623f99", logo: "amarelo" }

        case "4":
          return { text: "#a7a9ac", cor: "#fffeff", logo: "azul" }
        case "5":
          return { text: "#a7a9ac", cor: "#fffeff", logo: "azul" }

        case "6":
          return { text: "#e03da6", cor: "#f6a1be", logo: "amarelo" }
        case "7":
          return { text: "#e03da6", cor: "#f6a1be", logo: "amarelo" }

        case "8":
          return { text: "#465eff", cor: "#73cde1", logo: "amarelo" }
        case "9":
          return { text: "#465eff", cor: "#73cde1", logo: "amarelo" }

        case "10":
          return { text: "#3c2415", cor: "#8b5320", logo: "amarelo" }
        case "11":
          return { text: "#3c2415", cor: "#8b5320", logo: "amarelo" }

        case "12":
          return { text: "#a7a9ac", cor: "#241f20", logo: "amarelo" }
        case "13":
          return { text: "#a7a9ac", cor: "#241f20", logo: "amarelo" }

        case "14":
          return { text: "#ffb7ab", cor: "#eb3421", logo: "amarelo" }
        case "15":
          return { text: "#ffb7ab", cor: "#eb3421", logo: "amarelo" }

        case "16":
          return { text: "#c13206", cor: "#f58a29", logo: "amarelo" }
        case "17":
          return { text: "#c13206", cor: "#f58a29", logo: "amarelo" }

        case "18":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }
        case "19":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }

        case "20":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }
        case "21":
          return { text: "#f7941d", cor: "#fdfc30", logo: "azul" }

        case "22":
          return { text: "#006838", cor: "#73bd46", logo: "amarelo" }
        case "23":
          return { text: "#006838", cor: "#73bd46", logo: "amarelo" }

        case "24":
          return { text: "#73cde1", cor: "#295ca9", logo: "amarelo" }
        case "25":
          return { text: "#73cde1", cor: "#295ca9", logo: "amarelo" }

        case "26":
          return { text: "#d38fff", cor: "#6e2a8f", logo: "amarelo" }
        case "27":
          return { text: "#d38fff", cor: "#6e2a8f", logo: "amarelo" }

        default:
          return { text: "", cor: "" }
      }
    }


    function generateJson(rows, columns) {
      const data = [];
      for (let linha = 0; linha < rows; linha++) {
        for (let coluna = 0; coluna < columns; coluna++) {
          const obj = {
            linha: linha.toString(),
            coluna: coluna.toString(),
            valido: true,
            cor: distribuiCor(coluna.toString()).cor,
            text: distribuiCor(coluna.toString()).text,
            logo: distribuiCor(coluna.toString()).logo
          };
          data.push(obj);
        }
      }
      return JSON.stringify(data);
    }

    const rows = 18;
    const columns = 28;

    const json_data = generateJson(rows, columns);
    // console.log(json_data);
  }, [])

  function colorBottom() {
    const colorLogoBlue = [1, 3, 6, 9];
    return !colorLogoBlue.includes(currentStep);
  }

  function selecionarPergunta(index) {
    const otherSteps = [...GlobalContext.steps];
    const updatedStep = { ...otherSteps[currentStep - 2] };

    updatedStep.perguntas = updatedStep.perguntas.map((e, indexPergunta) => ({
      ...e,
      active: indexPergunta === index,
    }));

    otherSteps[currentStep - 2] = updatedStep;

    GlobalContext.setStep(otherSteps);
  }

  function changeStep(value) {
    setCurrentStep(value);
  }

  // Renderiza o componente correspondente com base no valor de currentStep
  const CurrentStepComponent = steps[currentStep - 1];

  return (
    <div className="page-quiz">
      <CurrentStepComponent
        currentStep={currentStep}
        changeStep={changeStep}
        selecionarPergunta={selecionarPergunta}
      />
      <div className="bottom">
        <LogoSvg inverter={colorBottom()} />
      </div>
    </div>
  );
}
