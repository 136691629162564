import React, { useState } from "react";
import "./steps.scss";
import { useGlobalContext } from "../../../context/GlobalContext";

export default function StepsStatus(props) {
  const GLobalContext = useGlobalContext()
  function controllClass(index) {
    const etapaPoint = index + 1;
    if(props.currentStep <= etapaPoint ) {
      return 'disabled'
    }
    return '';
  }

  return <>
    <div className={`component-steps ${props.currentStep === 3 && 'dark'}`}>
      <p>Pergunta {props.currentStep - 1}/<small>6</small></p>
      <div className="steps">
        {GLobalContext.steps.map((e, index) => (
          <div className={`step-point ${controllClass(index)}`} style={{backgroundColor: e.color}}></div>
        ))}
      </div>
    </div>
  </>;
}
