import React, { useEffect } from "react";
import "./steps.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import QuizComponent from "../../../components/estruturaQuiz";

export default function Step03(props) { 
  const GlobalContext = useGlobalContext();
  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#FCFC30');
    document.documentElement.style.setProperty('--bgCard', '#FCFC30');
    document.documentElement.style.setProperty('--textButton', '#FCFC30');

    document.documentElement.style.setProperty('--border', '#F97A70');
    document.documentElement.style.setProperty('--bgbutton', '#F97A70');
    document.documentElement.style.setProperty('--text', '#F97A70');

    document.documentElement.style.setProperty('--element01', '#FF3131');
    document.documentElement.style.setProperty('--element02', '#FFA10B');

    document.documentElement.style.setProperty('--bgPerguntaBox', GlobalContext.steps[props.currentStep - 2].colorBgPerguntaBox);
  },[]);

  return <QuizComponent {...props} nextStep={4} />;
}
