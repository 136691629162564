import React, { useEffect, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import MaskLoading from "../../components/maskLoading/maskLoading";
import { Firestore } from "../../utils/firebaseUtils";
import domtoimage from 'dom-to-image';

export default function AdminPage() {
  const [entrar, setEntrar] = useState(false);
  const [data, setData] = useState([]);
  const [senha, setSenha] = useState('');
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState({ show: false, quiz: {} });
  const [modalLiberar, setmodalLiberar] = useState({ show: false, id: 0 });
  const [showQuiz, setshowQuiz] = useState(null);

  function logar() {
    if (senha === 'stories@123') {
      getData();
    } else {
      toast.error('Senha inválida')
    }
  }

  useEffect(() => {
    Firestore.collection('cards').add({
      "linha": "0",
      "coluna": "22",
      "valido": true,
      "cor": "#73bd46",
      "text": "#006838",
      "logo": "amarelo",
      "id": 23
  })
  },[])

  function getData() {
    setloading(true);
    Firestore.collection('users')
      .orderBy('date', 'desc')
      .onSnapshot(async (snap) => {
        const results = [];
        snap.forEach(async (doc) => {
          const valores = doc.data();
          const fireBaseTime = new Date(
            valores.date.seconds * 1000 + valores.date.nanoseconds / 1000000,
          );
          results.push({
            ...valores,
            id: doc.id,
            data: fireBaseTime.toDateString(),
          });
        });
        console.log(results);
        setData(results);
        setEntrar(true);
        setTimeout(() => {
          setloading(false);
        }, 300);
      });
  }

  function handlePrint() {
    const content = document.querySelector('.card-print');
    Firestore.collection('users').doc(modal.id).update({ imprimiu: true });
    domtoimage.toPng(content).then(function (dataUrl) {
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Imprimir</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }
                .print-content {
                  margin: 0;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100vh;
                  width: 100vw;
                }
                .print-image {
                  display: block;
                  max-width: 100%;
                  height: auto;
                }
              }
            </style>
          </head>
          <body>
            <div class="print-content">
              <img class="print-image" src="${dataUrl}" />
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    });
  }

  function removerCard() {
    if (modalLiberar.id) {
      setloading(true);
      Firestore.collection('cards').where('id', '==', modalLiberar.id).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete()
              .then(() => {
                Firestore.collection('users').doc(modalLiberar.uidRemove).update({ removido: true });
                setloading(false);
                setmodalLiberar({});
                toast.success("Card liberado");
              })
              .catch((error) => {
                setloading(false);
                toast.error("Erro ao liberar o card");
              });
          });
        })
        .catch((error) => {
          setloading(false);
          toast.error("Erro ao buscar o card");
        });
    } else {
      toast.error("Erro ao buscar o card");
    }
  }

  function quantidadeCards(type) {
    if (type === 'registros') {
      return data.length;
    }
    if (type === 'impressos') {
      return data.filter(e => e.imprimiu).length;
    }
    if (type === 'cancelados') {
      return data.filter(e => e.removido).length;
    }
    if (type === 'impresso-removido') {
      return data.filter(objeto => objeto.removido === true && objeto.imprimiu === true).length;
    }
    return "--";
  }

  function classTable(item) {
    let classReturn =  '';
    if(item.imprimiu) {
      classReturn += ' print';
    }
    if (item.removido) {
      classReturn += ' removed';
    }
    if(item.imprimiu && item.removido ) {
      classReturn += ' print-removed'
    }
    return classReturn;
  }


  return (
    <div>
      {!entrar ?
        <div className="page-login">
          <div className="login">
            <input
              type="password"
              placeholder="Senha"
              value={senha}
              onChange={(value) => setSenha(value.target.value)}
            />
            <button onClick={logar}>Logar</button>
          </div>
        </div>
        :
        <>
          <div className="relatorios">
            <div className="item">
              <p>Qtd. Registros</p>
              <span>{quantidadeCards('registros')}</span>
            </div>
            <div className="item">
              <p>Qtd. Impressos</p>
              <span>{quantidadeCards('impressos')}</span>
            </div>
            <div className="item">
              <p>Qtd. cancelados</p>
              <span>{quantidadeCards('cancelados')}</span>
            </div>
            <div className="item">
              <p>Qtd. Impressos e Cancelados</p>
              <span>{quantidadeCards('impresso-removido')}</span>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Card</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Termo de Aceite</th>
                <th>Termo de Marketing</th>
                <th>Descrição</th>
                <th>Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <>
                  <tr key={index} className={classTable(item)}>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.card.coluna}x{item.card.linha}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.nome}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.email}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.telefone}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.aceiteMarketing ? 'sim' : 'não'}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.direitoDeImagens ? 'sim' : 'não'}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.descricao}</td>
                    <td onClick={() => setshowQuiz(showQuiz === index ? null : index)}>{item.data}</td>
                    <td>
                      {!item.removido && <button onClick={() => setmodalLiberar({ show: true, id: item.card.id, uidRemove: item.id })}>Liberar Card</button>}
                      <button onClick={() => setmodal({ show: true, quiz: item.quiz, id: item.id, descricao: item.descricao, card: item.card, nome: item.nome })}>Imprimir</button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={200} className="tdrespostas">
                      <div className={showQuiz === index ? 'respostas-quiz active' : 'respostas-quiz'}>
                        {item.quiz.map(e => (
                          <div>
                            <h4>{e.enunciado}</h4>
                            <ul>
                              {e.perguntas.map(pergunta => (
                                <li className={pergunta.active && 'active'}>{pergunta.pergunta}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr></tr>
                </>
              ))}
            </tbody>
          </table>
        </>
      }
      {loading &&
        <div className="global-loading">
          <MaskLoading />
        </div>
      }
      {modal.show &&
        <div className="modal-imprimir">
          <div className="content-print">
            <div className="actions">
              <button onClick={() => setmodal({})}>Fechar</button>
              <button onClick={handlePrint}>Imprimir</button>
            </div>
            <div className="card-print">
              <div className="modelo-print" style={{ backgroundColor: modal.card.cor }}>
                <div className="numerocard" style={{ color: modal.card.text }}>{modal.card.coluna}x{modal.card.linha}</div>
                <span style={{ color: modal.card.text }}>
                  <div>
                    {modal.descricao}
                    <br />
                    <br />
                    {modal.nome}
                  </div>
                </span>
                <div className={`rodape ${modal.card.logo}`}>
                  <span>#InvistaEmVcBB</span>
                  <span>
                    <svg width="70" height="84" viewBox="0 0 70 84" xmlns="http://www.w3.org/2000/svg">
                      <path d="M70 0H0V84H70V0Z" fill="transparent" />
                      <path d="M13.1132 49.9628L13.2387 55.7737L22.2109 49.9628L17.6516 46.8913L13.1132 49.9628Z" fill="#465EFF" />
                      <path d="M13.3642 41.3087L34.885 55.6492L56.9077 41.1842L47.81 35.2695L36.9137 42.4917L41.703 45.5839L35.5961 49.61L22.692 40.9559L43.6271 26.9683L39.1933 24.0005L13.3642 41.3087Z" fill="#465EFF" />
                      <path d="M48.1655 18.1897L52.5993 21.2819L57.0122 18.3142V12.3995L48.1655 18.1897Z" fill="#465EFF" />
                      <path d="M35.1359 12.2542L13.2387 26.74L22.3155 32.7791L33.2118 25.5363L28.548 22.4648L34.5294 18.3142L47.4544 26.9682L26.6448 40.9559L30.9322 43.9236L56.7822 26.74L35.1359 12.2542Z" fill="#465EFF" />
                    </svg>

                  </span>
                </div>
              </div>
              <div className="modelo-print" style={{ backgroundColor: modal.card.cor }}>
                <div className="numerocard" style={{ color: modal.card.text }}></div>
                <span style={{ color: modal.card.text }}>
                  {modal.descricao}
                  <br />
                  <br />
                  {modal.nome}
                </span>
                <div className={`rodape ${modal.card.logo}`}>
                  <span>#InvistaEmVcBB</span>
                  <span>
                    <svg width="70" height="84" viewBox="0 0 70 84" xmlns="http://www.w3.org/2000/svg">
                      <path d="M70 0H0V84H70V0Z" fill="transparent" />
                      <path d="M13.1132 49.9628L13.2387 55.7737L22.2109 49.9628L17.6516 46.8913L13.1132 49.9628Z" fill="#465EFF" />
                      <path d="M13.3642 41.3087L34.885 55.6492L56.9077 41.1842L47.81 35.2695L36.9137 42.4917L41.703 45.5839L35.5961 49.61L22.692 40.9559L43.6271 26.9683L39.1933 24.0005L13.3642 41.3087Z" fill="#465EFF" />
                      <path d="M48.1655 18.1897L52.5993 21.2819L57.0122 18.3142V12.3995L48.1655 18.1897Z" fill="#465EFF" />
                      <path d="M35.1359 12.2542L13.2387 26.74L22.3155 32.7791L33.2118 25.5363L28.548 22.4648L34.5294 18.3142L47.4544 26.9682L26.6448 40.9559L30.9322 43.9236L56.7822 26.74L35.1359 12.2542Z" fill="#465EFF" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {modalLiberar.show &&
        <div className="modalLiberarCard">
          <div className="content-modalLiberarcard">
            <p>Esta ação irá liberar o card para ser escolhido por outro usuário, e não poderá ser desfeito, deseja continuar?</p>
            <div className="action">
              <button onClick={removerCard}>Sim</button>
              <button onClick={() => setmodalLiberar({})}>Não</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
