export const TOKEN_KEY = "token";
export const ME = {};
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getMe = () => localStorage.getItem(ME);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setMe = user => {
  localStorage.setItem(ME, JSON.stringify(user));
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ME);
};