import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAiLciI7gbzY0o9FBQG9rRp92UB2Kioa6w",
  authDomain: "bb-invista-em-voce.firebaseapp.com",
  projectId: "bb-invista-em-voce",
  storageBucket: "bb-invista-em-voce.appspot.com",
  messagingSenderId: "908440199249",
  appId: "1:908440199249:web:0741bbe51ac2e1ae795870",
  measurementId: "G-64WXEFQ09Y"
};

firebase.initializeApp(firebaseConfig);
export const admin = firebase.admin;
export const auth = firebase.auth;
export const Firestore = firebase.firestore();
export const Storage = firebase.storage();
