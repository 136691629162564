import axios from "axios";
import { getToken } from "./auth";
import { logout } from "../services/auth";
import { withRouter } from "react-router-dom";

const api = axios.create({
  baseURL: "https://us-central1-bb-invista-em-voce.cloudfunctions.net/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout();
      this.props.history.push("/login");
    }
    return Promise.reject(error.response);
  }
);

export default withRouter(api);
