import React from 'react';

import './style.css';

import Loading from '../../assets/img/loading.svg';

export default function MaskLoading() {
  return (
    <div className="filterLoading">
      <div>
        <img src={Loading} className="loadingMask" />
      </div>
    </div>
  );
}
