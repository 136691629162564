import React from "react";
import "./index.scss";
import Cores from "../../utils/cores.json";
import { useHistory } from "react-router-dom";


export default function PageParabens() {

  function animation() {
    const items = Cores.map((e, index) => (
      <div
        className={`color color-${index}`}
        style={{ animationDelay: `${index * 0.1}s` }}
        key={index}
      ></div>
    ));

    const firstHalfLength = Math.ceil(items.length * 0.7);
    const firstHalf = items.slice(0, firstHalfLength);
    const secondHalf = items.slice(firstHalfLength);

    return (
      <>
        <div className="dividir" style={{ animationDelay: `1.9s` }}>
          {firstHalf}
        </div>
        <div className="content-home" style={{ animationDelay: `2.6s` }}>
          <div className="header">
            <div>
              <h4>Parabéns! Está investindo em você!</h4>
              <h3>Aprendendo como conquistar a liberdade financeira para ser quem vc é</h3>
            </div>
          </div>
          <div className="person">
            <img src={require("../../assets/img/parabens.png")} alt="Person" />
          </div>
        </div>
        <div className="dividir" style={{ animationDelay: `1.9s` }}>
          {secondHalf}
        </div>
      </>
    );
  }

  return <>
    <div className="content-parabens">
      <div className="animation">
        {animation()}
      </div>
    </div>
  </>;
}
