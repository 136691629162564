import React, { useState } from "react";
import "./index.scss";
import { useGlobalContext } from "../../context/GlobalContext";
import StepsStatus from "../../pages/quiz/steps/steps";
import { ReactComponent as Check } from "../../assets/img/check.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



export default function QuizComponent(props) {
  const [next, setNext] = useState(false);

  const history = useHistory();

  const GlobalContext = useGlobalContext();

  function checkDisabled() {
    if (GlobalContext.steps[props.currentStep - 2].perguntas.find(e => e.active)) {
      return false;
    }
    return true;
  }

  function nextStep() {
    setNext(true);
    setTimeout(() => {
      if(props.finish) {
        history.push('/finish');
      } else {
        props.changeStep(props.nextStep);
      }
    }, 1000);
  }

  return <>
    <div className="step step-02">
      <div className="content-cardStep">
        <div>
          <div className={`controll-steps ${next && 'close'}`}>
            {props.currentStep < 8 && <StepsStatus steps={GlobalContext.steps} currentStep={props.currentStep} />}
          </div>
          <div className={`card-form ${next && 'close'} ${GlobalContext.steps[props.currentStep - 2].perguntasBox && 'quiz-Box'}`}>
            <div className="form">
              <div className="quiz">
                <h2>{GlobalContext.steps[props.currentStep - 2].enunciado}</h2>
                <div className="respostas">
                  {GlobalContext.steps[props.currentStep - 2].perguntas.map((e, index) => (
                    <div className={e.active && "active"} onClick={() => props.selecionarPergunta(index)}>
                      {!GlobalContext.steps[props.currentStep - 2].perguntasBox &&
                        <span>{index + 1}</span>
                      }
                      <div className="select"><Check /></div>
                      <p>{e.pergunta}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="action">
                <button disabled={checkDisabled()} onClick={nextStep}>Próxima</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`animation-step ${next && 'close'}`}>
        <div className="element01"></div>
        <div className="element02"></div>
        <div className="revela"></div>
      </div>
    </div>
  </>;
}
