import React from "react";
import "./index.scss";

export default function Login() {
  return (
    <>
      Login
    </>
  );
}
